// src/components/RoleManagement.js
import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from "@mui/material";
import { Link } from "react-router-dom";
import { useServer } from "../context/ServerContext";
import { useServerSettings } from "../context/ServerSettingsContext";

const RoleManagement = () => {
  const { server, serverRoles } = useServer();
  const {
    roleManagementSettings,
    setRoleManagementSettings,
    integrations,
    saveRoleManagementSettings,
    integrationsLoading,
    roleManagementLoading
  } = useServerSettings();

  useEffect(() => { }, [roleManagementSettings, serverRoles, integrations]);

  const handleSave = () => {
    saveRoleManagementSettings();
  };

  if (roleManagementLoading || integrationsLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        Role Management
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={roleManagementSettings?.enabled || false}
              onChange={(event) =>
                setRoleManagementSettings({
                  ...roleManagementSettings,
                  enabled: event.target.checked,
                })
              }
            />
          }
          label="Enable Role Management?"
        />
      </FormGroup>
      <br />
      {/* OnJoin and Streamer Role */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="streamer-role">Streamer Role</InputLabel>
            <Select
              label="Streamer Role"
              value={roleManagementSettings?.streamerRole || ""}
              onChange={(event) =>
                setRoleManagementSettings({
                  ...roleManagementSettings,
                  streamerRole: event.target.value,
                })
              }
            >
              <MenuItem value={""}>None</MenuItem>
              {serverRoles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="on-join-role">On-Join Role</InputLabel>
                <Select
                  label="On-Join Role"
                  value={roleManagementSettings?.onJoin?.role || ""}
                  onChange={(event) =>
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      onJoin: {
                        ...roleManagementSettings.onJoin,
                        role: event.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={""}>None</MenuItem>

                  {serverRoles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {roleManagementSettings?.onJoin?.role && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          roleManagementSettings?.onJoin?.guestMode || false
                        }
                        onChange={(event) =>
                          setRoleManagementSettings({
                            ...roleManagementSettings,
                            onJoin: {
                              ...roleManagementSettings.onJoin,
                              guestMode: event.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Should on-join be temporary?"
                  />
                </FormGroup>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Custom Roles */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Custom Roles
        </Typography>
        {Object.entries(roleManagementSettings?.customRoles || {}).map(
          ([name, role], index) => (
            <Box key={index} sx={{ pb: 1 }}>
              <Grid container spacing={2} key={index}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(event) => {
                      const newCustomRoles = {
                        ...roleManagementSettings?.customRoles,
                      };
                      delete newCustomRoles[name];
                      newCustomRoles[event.target.value] = role;
                      setRoleManagementSettings({
                        ...roleManagementSettings,
                        customRoles: newCustomRoles,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor={`custom-role-${index}`}>
                      Role
                    </InputLabel>
                    <Select
                      label="Role"
                      value={role}
                      onChange={(event) => {
                        const newCustomRoles = {
                          ...roleManagementSettings?.customRoles,
                        };
                        newCustomRoles[name] = event.target.value;
                        setRoleManagementSettings({
                          ...roleManagementSettings,
                          customRoles: newCustomRoles,
                        });
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {serverRoles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} container alignItems="center">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      const newCustomRoles = {
                        ...roleManagementSettings?.customRoles,
                      };
                      delete newCustomRoles[name];
                      setRoleManagementSettings({
                        ...roleManagementSettings,
                        customRoles: newCustomRoles,
                      });
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setRoleManagementSettings({
                ...roleManagementSettings,
                customRoles: {
                  ...roleManagementSettings?.customRoles,
                  [`Custom Role ${Object.keys(roleManagementSettings?.customRoles).length + 1
                    }`]: "",
                },
              });
            }}
          >
            Add Custom Role
          </Button>
        </Box>
      </Box>
      {/* Integrations */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Role Integrations
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Integrations apply roles based on in-game metrics. Choose an
          Integration below to edit it.
        </Typography>
        <List>
          {Object.values(integrations).map((integration) => {
            return (
              <ListItem
                key={integration.slug}
                button
                component={Link}
                to={`/servers/${server.id}/dashboard/role-management/${integration.slug}`}
              >
                <ListItemText
                  primary={integration.name}
                  secondary={
                    roleManagementSettings?.integrations?.[integration.slug]
                      ?.enabled
                      ? "Enabled"
                      : "Disabled"
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default RoleManagement;
