import React, { useEffect, useRef } from "react";
import drawWoWGuildCrest from "../utils/GuildCrestGenerator";

const WoWGuildCrest = ({ data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    console.log(data);
    try {
      drawWoWGuildCrest(data, canvasRef.current);
    } catch (error) {
      console.log("Unable to Draw Guild Tabard", error);
    }
  }, [data]);

  return <canvas ref={canvasRef} width="50" height="50" />;
};

export default WoWGuildCrest;
