import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";

import { useServer } from "../context/ServerContext";
import { useUser } from "../context/UserContext";
import { useGuild } from "../context/GuildContext";
import CharacterClass from "../data/classes.json";

function GuildManagement() {
  const navigate = useNavigate();
  const { serverId, guildId } = useParams();
  const { serverRoles } = useServer();
  const { user } = useUser();
  const { availableGuilds, setGuild, guild } = useGuild();
  const [guildRanks, setGuildRanks] = useState({});
  const [guildRankExamples, setGuildRankExamples] = useState({});
  const [loading, setLoading] = useState(true);
  const guildRankNumbers = [...Array(10).keys()];

  useEffect(() => {
    // Find and Get the Guild from availableGuilds
    // If not found, redirect to guild selection page
    console.log(availableGuilds);
    if (guild?.id == guildId) return;
    const selectedGuild = availableGuilds.find((g) => g.id == guildId);
    if (!selectedGuild) {
      // Redirect to guild selection page
      navigate(`/servers/${serverId}/dashboard/guilds`);
      return;
    }
    setGuild(selectedGuild);
  }, [availableGuilds, serverId, navigate, guildId]);

  useEffect(() => {
    // Control Loading State
    if (!guildRanks?.guildID) return;
    setLoading(false);
  }, [guildRanks]);

  useEffect(() => {
    // Get the Guild Ranks and Examples
    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/roles`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setGuildRanks(response.data);
      })
      .catch((error) => {
        console.log("Error fetching guild ranks:", error);
      });

    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/roles/example`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setGuildRankExamples(response.data);
      })
      .catch((error) => {
        console.log("Error fetching guild rank examples:", error);
      });
  }, []);

  const handleSelectChange = (rankId, event) => {
    setGuildRanks({
      ...guildRanks,
      [`rank${rankId}Roles`]: [event.target.value],
    });
  };

  const handleSaveSettings = () => {
    const rolesSettings = guildRankNumbers.reduce((acc, rankNum) => {
      const rankRoles = `rank${rankNum}Roles`;
      return {
        ...acc,
        [rankRoles]:
          guildRanks?.[rankRoles]?.length > 0 ? guildRanks[rankRoles] : null,
      };
    }, {});

    fetch(
      `${process.env.REACT_APP_JEEVES_API_URL}/server/${serverId}/guild/${guildId}/roles`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rolesSettings),
      }
    ).catch((error) => {
      console.log("Error saving guild ranks:", error);
    });
  };

  const handleGoBack = () => {
    navigate(`/servers/${serverId}/dashboard/guilds`);
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ mt: 3 }}>
      <Button onClick={handleGoBack}>{"< Go Back"}</Button>
      <Typography variant="h4" gutterBottom>
        {guild?.name}
      </Typography>
      {guildRankNumbers.map((rankNum) => {
        const exampleChar = guildRankExamples?.[rankNum];
        let color = "inherit"; // default color if no class found
        if (exampleChar) {
          const characterClass = CharacterClass.find(
            (cls) => cls.id === exampleChar.playable_class.id
          );
          if (characterClass) {
            color = characterClass.color;
          }
        }
        return (
          <Box key={rankNum} sx={{ pb: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="h6" gutterBottom>
                  Rank {rankNum + 1}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Example:{" "}
                  <span style={{ color: color }}>
                    {exampleChar?.name || "No Characters Found"}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor={`role-${rankNum + 1}`}>
                    Choose Role(s)
                  </InputLabel>
                  <Select
                    label="Choose Role(s)"
                    multiple
                    value={guildRanks?.[`rank${rankNum}Roles`] || []}
                    onChange={(event) => handleSelectChange(rankNum, event)}
                  >
                    {serverRoles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      })}
      <Button variant="contained" color="primary" onClick={handleSaveSettings}>
        Save Settings
      </Button>
    </Box>
  );
}

export default GuildManagement;
