import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from "@mui/material";

import { useServerSettings } from "../context/ServerSettingsContext";

import EnumeratedRoleMap from "./EnumeratedRoleMap";
import RangeRoleMap from "./RangeRoleMap";
import ArrayRoleSelector from "./ArrayRoleSelector";

function Integration() {
  const {
    roleManagementSettings,
    setRoleManagementSettings,
    serverRoles,
    integrations,
    saveRoleManagementSettings,
  } = useServerSettings();
  const navigate = useNavigate();
  const { serverId, integrationSlug } = useParams();

  useEffect(() => {}, [
    roleManagementSettings,
    serverRoles,
    integrations,
    integrationSlug,
  ]);

  const handleSave = () => {
    saveRoleManagementSettings();
  };

  if (!integrations?.[integrationSlug]) {
    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2">
          Integration {integrationSlug} not found.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography
        variant="body2"
        onClick={() =>
          navigate(`/servers/${serverId}/dashboard/role-management`)
        }
        style={{ cursor: "pointer" }}
      >
        &lt; Back To Role Management
      </Typography>
      <br />
      <Typography variant="h4" gutterBottom>
        Manage {integrations[integrationSlug].name}
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={
                roleManagementSettings?.integrations?.[integrationSlug]
                  .enabled || false
              }
              onChange={() =>
                setRoleManagementSettings({
                  ...roleManagementSettings,
                  integrations: {
                    ...roleManagementSettings.integrations,
                    [integrationSlug]: {
                      ...roleManagementSettings.integrations?.[integrationSlug],
                      enabled:
                        !roleManagementSettings?.integrations?.[integrationSlug]
                          .enabled,
                    },
                  },
                })
              }
            />
          }
          label={
            roleManagementSettings?.integrations?.[integrationSlug].enabled
              ? "Enabled"
              : "Disabled"
          }
        />
      </FormGroup>
      <br />
      {roleManagementSettings?.integrations?.[integrationSlug].enabled && (
        <Box>
          {/* Add your three dropdown selectors here */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="characters">Characters</InputLabel>
                <Select
                  label="Characters"
                  value={
                    roleManagementSettings?.integration?.[integrationSlug]
                      ?.characters ||
                    integrations?.[integrationSlug]?.characters.find(
                      (v) => v.default == true
                    ).id
                  }
                  onChange={(event) =>
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      integrations: {
                        ...roleManagementSettings.integrations,
                        [integrationSlug]: {
                          ...roleManagementSettings.integrations?.[
                            integrationSlug
                          ],
                          characters: event.target.value,
                        },
                      },
                    })
                  }
                >
                  {integrations[integrationSlug].characters.map(
                    (characterOptions) => (
                      <MenuItem
                        key={characterOptions.id}
                        value={characterOptions.id}
                      >
                        {characterOptions.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="characters">Management</InputLabel>
                <Select
                  label="Management"
                  value={
                    roleManagementSettings?.integration?.[integrationSlug]
                      ?.management ||
                    integrations?.[integrationSlug]?.management.find(
                      (v) => v.default == true
                    ).id
                  }
                  onChange={(event) =>
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      integrations: {
                        ...roleManagementSettings.integrations,
                        [integrationSlug]: {
                          ...roleManagementSettings.integrations?.[
                            integrationSlug
                          ],
                          management: event.target.value,
                        },
                      },
                    })
                  }
                >
                  {integrations[integrationSlug].management.map(
                    (managementOptions) => (
                      <MenuItem
                        key={managementOptions.id}
                        value={managementOptions.id}
                      >
                        {managementOptions.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="characters">Mode</InputLabel>
                <Select
                  label="Mode"
                  value={
                    roleManagementSettings?.integration?.[integrationSlug]
                      ?.mode ||
                    integrations?.[integrationSlug]?.mode.find(
                      (v) => v.default == true
                    ).id
                  }
                  onChange={(event) =>
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      integrations: {
                        ...roleManagementSettings.integrations,
                        [integrationSlug]: {
                          ...roleManagementSettings.integrations?.[
                            integrationSlug
                          ],
                          mode: event.target.value,
                        },
                      },
                    })
                  }
                >
                  {integrations[integrationSlug].mode.map((modeOptions) => (
                    <MenuItem key={modeOptions.id} value={modeOptions.id}>
                      {modeOptions.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />

          {integrations?.[integrationSlug]?.controlOption.some(
            (v) => v.id === "custom"
          ) && (
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        roleManagementSettings?.integrations?.[integrationSlug]
                          ?.custom === true
                      }
                      onChange={() =>
                        setRoleManagementSettings({
                          ...roleManagementSettings,
                          integrations: {
                            ...roleManagementSettings.integrations,
                            [integrationSlug]: {
                              ...roleManagementSettings.integrations?.[
                                integrationSlug
                              ],
                              custom:
                                !roleManagementSettings?.integrations?.[
                                  integrationSlug
                                ]?.custom,
                            },
                          },
                        })
                      }
                    />
                  }
                  label="Add a custom rolemap?"
                />
              </FormGroup>
              {roleManagementSettings?.integrations?.[integrationSlug]
                ?.custom === true &&
                integrations?.[integrationSlug]?.roleMap?.type === "enum" && (
                  <EnumeratedRoleMap />
                )}
              {roleManagementSettings?.integrations?.[integrationSlug]
                ?.custom === true &&
                integrations?.[integrationSlug]?.roleMap?.type === "range" && (
                  <RangeRoleMap />
                )}
              {roleManagementSettings?.integrations?.[integrationSlug]
                ?.custom === true &&
                integrations?.[integrationSlug]?.roles && <ArrayRoleSelector />}
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
      <br />
    </Box>
  );
}

export default Integration;
