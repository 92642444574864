// src/pages/TermsOfService.js
import React from "react";
import { Container, Typography } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container>
      <br />
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <br />
      <Typography variant="body1" paragraph>
        Terms of Service for Jeeves Discord Bot
      </Typography>
      <Typography variant="body1" paragraph>
        Last Updated: [4/21/23]
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms of Service ("Terms") govern your use of the Jeeves Discord
        Bot ("Jeeves," "we," "us," or "our"). By using Jeeves, you agree to be
        bound by these Terms. If you do not agree with any part of these Terms,
        you must not use Jeeves.
      </Typography>
      <Typography variant="body1" paragraph>
        1. Acceptable Use
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use Jeeves only for purposes that are legal and in
        accordance with these Terms and any applicable laws, regulations, or
        guidelines. You shall not use Jeeves to engage in any activity that is
        harmful, harassing, discriminatory, or otherwise objectionable.
      </Typography>
      <Typography variant="body1" paragraph>
        2. Restrictions
      </Typography>
      <Typography variant="body1" paragraph>
        You shall not:
      </Typography>
      <Typography variant="body1" paragraph>
        a) Modify, reverse engineer, decompile, or disassemble Jeeves.
      </Typography>
      <Typography variant="body1" paragraph>
        b) Use Jeeves to distribute any harmful, malicious, or deceptive
        content.
      </Typography>
      <Typography variant="body1" paragraph>
        c) Use Jeeves to collect or store personal information about other users
        without their express permission.
      </Typography>
      <Typography variant="body1" paragraph>
        d) Attempt to disrupt, interfere with, or compromise the integrity or
        security of Jeeves, our servers, or any associated systems.
      </Typography>
      <Typography variant="body1" paragraph>
        3. Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        All rights, title, and interest in and to Jeeves, including, but not
        limited to, any copyrights, trademarks, trade secrets, and other
        intellectual property rights, are owned by us or our licensors. Your use
        of Jeeves does not grant you any rights or licenses in or to our
        intellectual property.
      </Typography>
      <Typography variant="body1" paragraph>
        4. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        Jeeves is provided "as is" and "as available" without any warranties of
        any kind, either express or implied, including, but not limited to,
        warranties of merchantability, fitness for a particular purpose, or
        non-infringement. We do not warrant that Jeeves will be error-free,
        uninterrupted, or secure, or that any defects will be corrected.
      </Typography>
      <Typography variant="body1" paragraph>
        5. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the maximum extent permitted by law, we shall not be liable for any
        direct, indirect, incidental, special, consequential, or punitive
        damages, including, but not limited to, loss of profits, data, or use,
        arising out of or in connection with your use of Jeeves, whether based
        on contract, tort, or any other legal theory, even if we have been
        advised of the possibility of such damages.
      </Typography>
      <Typography variant="body1" paragraph>
        6. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to indemnify, defend, and hold us and our affiliates,
        directors, officers, employees, and agents harmless from any claims,
        damages, losses, liabilities, costs, and expenses, including reasonable
        attorneys' fees, arising out of or in connection with your use of
        Jeeves, your violation of these Terms, or your infringement of any
        intellectual property or other rights of any third party.
      </Typography>
      <Typography variant="body1" paragraph>
        7. Changes to These Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to update or modify these Terms at any time. Any
        changes will be effective immediately upon posting the updated Terms on
        this page. Your continued use of Jeeves after any changes to the Terms
        constitutes your acceptance of the updated Terms.
      </Typography>
      <Typography variant="body1" paragraph>
        8. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to suspend or terminate your access to Jeeves at
        any time, with or without notice, for any reason, including, but not
        limited to, your violation of these Terms.
      </Typography>
      <Typography variant="body1" paragraph>
        9. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms shall be governed by and construed in accordance with the
        laws of the jurisdiction in which we are based, without regard to its
        conflict of law provisions.
      </Typography>
      <Typography variant="body1" paragraph>
        10. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about these Terms, please contact
        us at [Your Contact Email Address].
      </Typography>
    </Container>
  );
};

export default TermsOfService;
