import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
} from "@mui/material";

const PremiumPage = () => {
  const premiumTiers = [
    {
      name: "Honored",
      price: 3,
      benefits: [
        "Personal Premium",
        "More Frequent Character Updates",
        "Discord Role in the Jeeves Server",
        "See & Access Upcoming Features Early!",
      ],
    },
    {
      name: "Revered",
      price: 5,
      benefits: [
        "Everything in Honored",
        "Server Wide Premium for a server of your choice",
        "Even More Frequent Character Scans",
        "Upgraded Role Management Options",
        "Multiple Role Reaction Channels",
        "Keystone Boards",
      ],
    },
    {
      name: "Exalted",
      price: 10,
      benefits: [
        "Everything in Revered",
        "Built for Extra-Large Guilds and Communities",
        "Even More Frequent Character Scans",
        "Link Multiple Guilds to a Single Server",
      ],
    },
  ];

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 8 }}>
          <Typography variant="h2" align="center" gutterBottom>
            Jeeves Premium
          </Typography>
          <Typography variant="h6" align="center" color="red">
            ***~~~!!! BETA SITE - NOT REFLECTIVE OF CURRENT OFFERINGS - TESTING
            ONLY - DOES NOT CONSITITUTE AN OFFER OR PROMISES OF GOODS OR
            SERVICES !!!~~~***
          </Typography>
          <Typography variant="h6" align="center">
            Jeeves is FREE for everyone to use. Want more? Level Up with Jeeves
            Premium!
          </Typography>
        </Box>
        <Grid container spacing={4} justifyContent="center">
          {premiumTiers.map((tier) => (
            <Grid item key={tier.name} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Typography variant="h5" align="center" gutterBottom>
                    {tier.name}
                  </Typography>
                  <Typography variant="h4" align="center" gutterBottom>
                    ${tier.price}
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Typography component="ul" variant="body1">
                    {tier.benefits.map((benefit) => (
                      <li key={benefit} style={{ textAlign: "left" }}>
                        {benefit}
                      </li>
                    ))}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "center", pb: 2 }}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    component="a"
                    href="https://patreon.com/JeevesBot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get {tier.name}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" align="center" gutterBottom>
            FAQ
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Box>
            <Typography variant="h5">What is Jeeves Premium?</Typography>
            <Typography variant="body1">
              Jeeves Premium allows you to automate roles and remove the need
              for commands. Normally for a user in your discord to get updated
              roles they need to run /roles update. Now with Premium the moment
              Jeeves detects a change in a user's character, it will
              automatically update their roles.
            </Typography>
            <br />
            <Typography variant="h5">
              What is Upgraded Role Management Options?
            </Typography>
            <Typography variant="body1">
              When configuring your Role Management settings certain limits are
              imposed, such as you can only link 1 discord role to each in-game
              guild rank. With Upgraded Role Management Options, you can now
              link 3 discord roles. The limits for different ranks are increased
              as follows:
            </Typography>
            <Typography component="ul" variant="body1">
              <li>Max Discord Roles per Guild Rank: 1 -> 3</li>
              <li>Max Discord Roles per Mythic Plus Rank: 1 -> 3</li>
              <li>Max Discord Roles per Achievement Rank: 1 -> 3</li>
              <li>Max Discord Roles per Pet Score: 1 -> 3</li>
              <li>Max Discord Roles per Faction: 1 -> 3</li>
              <li>Max Discord Roles per Class: 1 -> 3</li>
            </Typography>
            <br />
            <Typography variant="h5">
              What is the difference between Revered and Exalted?
            </Typography>
            <Typography variant="body1">
              Every teir comes with a certain ammount of budgeted daily
              character scans. Most Guilds will be fine with Revered. However,
              once you get to a certain size, you may need to upgrade to Exalted
              to get more frequent scans. Once you grow beyond a "Guild" and
              become a "Community" you will need to upgrade to Exalted.
            </Typography>
            <Typography variant="body1">
              This teir is tuned for Communities and unlocks additional
              restrictions and enables features support Multi-Region communities
              and communities with multiple guilds on the same server.
            </Typography>
            <Typography variant="body1">
              If you are interested in running Jeeves for a Large Community,
              please feel free to reach out on discord and we can discuss your
              needs.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PremiumPage;
