// src/components/JeevesDesktopClient.js
import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

import desktopClientKeystoneAutoImport from "../assets/desktop_client/keystone_auto_import.png";
import desktopClientDiscordPresence from "../assets/desktop_client/discord_presence_new.png";

const JeevesDesktopClient = () => {
  return (
    <Box>
      <Container>
        <Box py={6}>
          <Typography variant="h3" gutterBottom>
            Jeeves Desktop Client
          </Typography>
          <Typography variant="subtitle1">
            A lightweight desktop app for the Jeeves Discord Bot
          </Typography>
          <Box my={4}>
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" href="/path/to/windows/download">
                  Download for Windows
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  href="/path/to/macos/x64/download"
                  disabled
                >
                  Coming Soon for MacOS (x64)
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  href="/path/to/macos/arm/download"
                  disabled
                >
                  Coming Soon for MacOS (ARM)
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h5" gutterBottom>
            Features
          </Typography>
          <ul>
            <li>
              <Typography>
                Automatic importing of your character's keystones
              </Typography>
            </li>
            <li>
              <Typography>Automatic Character Scan Detection</Typography>
            </li>
            <li>
              <Typography>
                Adds Rich Presence for World of Warcraft to Discord, allowing
                you to show your character and status in-game right in your
                Discord profile
              </Typography>
            </li>
            <li>
              <Typography>
                Contributes data about the world to Jeeves to power features
              </Typography>
            </li>
          </ul>
          {/* Large Feature Advertisements */}
          <Grid container spacing={4} my={4}>
            {/* Feature 1 */}
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Automatic Keystone Import
              </Typography>
              <Typography variant="subtitle1">
                The desktop client detects your characters keystones
                automatically and imports them into Jeeves.
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: 500,
                  //bgcolor: "grey.800",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={desktopClientKeystoneAutoImport}
                  alt="Keystone Auto Import"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // This will make the image cover the entire box
                  }}
                />
              </Box>
            </Grid>

            {/* Feature 2 */}
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Auto Character Scan Detection
              </Typography>
              <Typography variant="subtitle1">
                The desktop client automatically notifies Jeeves when you logout
                that we should scan your character for updates.
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  bgcolor: "grey.800",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>Placeholder for Image 2</Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Mini Features */}
          <Grid container spacing={2} my={4}>
            {/* Mini Feature 1 */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Enhanced Discord Presence
              </Typography>

              <img
                src={desktopClientDiscordPresence}
                alt="Your Alt Text"
                style={{
                  width: 300,
                  height: 435, // Adjust height automatically
                }}
              />
            </Grid>
            {/* Mini Feature 2 */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Contribute Game Data
              </Typography>

              {/* Box for World Events */}
              <Box
                textAlign="center"
                p={2}
                mb={2}
                border={1}
                sx={{
                  bgcolor: "grey.900",
                }}
              >
                <Typography variant="h6">World Events</Typography>
              </Box>
              <Typography>
                - World Quests
                <br />
                - Invasions, Assaults, Hunts
                <br />- Weekly Rotations and more...
              </Typography>
              <Typography mt={2}>
                The desktop client detects changes in the world around you as
                you play and sends data back to Jeeves to power features.
              </Typography>

              {/* Box for Holidays */}
              <Box
                textAlign="center"
                p={2}
                mt={4}
                mb={2}
                border={1}
                sx={{
                  bgcolor: "grey.900",
                }}
              >
                <Typography variant="h6">Holidays</Typography>
              </Box>
              <Typography>
                - Darkmoon Fair
                <br />
                - Timewalking Events
                <br />- Nobblegarden and more...
              </Typography>
            </Grid>
            {/* Mini Feature 3 */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Silent! Efficient! Auto-Updates!
              </Typography>
              <Box
                textAlign="center"
                p={2}
                mb={2}
                border={1}
                sx={{
                  bgcolor: "grey.900",
                }}
              >
                <Typography variant="h6">Out of sight!</Typography>
              </Box>
              <Typography>
                Once setup, the desktop client will minimize itself to the
                system tray and run in the background while doing it's job.
              </Typography>

              {/* Box for Holidays */}
              <Box
                textAlign="center"
                p={2}
                mt={4}
                mb={2}
                border={1}
                sx={{
                  bgcolor: "grey.900",
                }}
              >
                <Typography variant="h6">Always Up-To-Date</Typography>
              </Box>
              <Typography>
                The desktop client automatically updates itself when a new
                version is available.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default JeevesDesktopClient;
